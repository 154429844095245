<template>
  <div>
    <slot name="header" />
    <div class="container mx-auto pb-12 max-w-sm">
      <div class="my-8 flex flex-col items-center">
        <div class="mb-8">
          <label class="text-center pr-4 block text-gray-800 text-sm font-bold mb-2">
            Høyde
          </label>
          <div class="flex items-center">
            <input
              v-model="data.model.height"
              type="number"
              pattern="[0-9]*"
              inputmode="numeric"
              maxlength="3"
              class="mr-2 block appearance-none w-32 bg-white px-4 py-3 rounded leading-snug focus:outline-none focus:shadow-outline text-black border border-gray-400"
            >

            <span class="text-xs">cm</span>
          </div>
        </div>

        <div>
          <label class="text-center pr-4 block text-gray-800 text-sm font-bold mb-2">
            Vekt
          </label>
          <div class="flex items-center">
            <input
              v-model="data.model.weight"
              type="number"
              pattern="[0-9]*"
              inputmode="numeric"
              maxlength="3"
              class="mr-2 block appearance-none w-32 bg-white px-4 py-3 rounded leading-snug focus:outline-none focus:shadow-outline text-black border border-gray-400"
            >

            <span class="text-xs">kg</span>
          </div>
        </div>
      </div>
    </div>
    <slot :is-valid="isValid" />
  </div>
</template>

<script>
import validate from "validate.js"

export default {
  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  created () {
    this.data.model = {
      height: "",
      weight: ""
    }
  },

  computed: {
    modelErrors () {
      const constraints = {
        numericality: {
          onlyInteger: true,
          greaterThan: 0,
        }
      }

      return validate.single(this.data.model.weight || 0, constraints) || validate.single(this.data.model.height || 0, constraints)
    },

    isValid () {
      return (typeof this.modelErrors === "undefined")
    }
  }
}
</script>
